import React from 'react';

import SEO from '../components/Layout/SEO';
import ContactHeader from '../components/ContactPage/ContactHeader';
import Layout from '../components/Layout/Layout';

const Contact = ({ location }) => {
	return (
		<Layout location={location}>
			<SEO title='Contact' />
			<ContactHeader />
		</Layout>
	);
};

export default Contact;
