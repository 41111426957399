import React from 'react';

const Divider = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'
			clipRule='evenodd'
			viewBox='0 0 1376 67'>
			<path
				fill={fill}
				d='M0 66.731L.433 0h1375.57v51.595s-16.738-1.401-25.123-1.319c-12.059.119-47.228 2.03-47.228 2.03s-24.995-4.032-41.427-4.498c-7.074-.201-17.955-2.488-25.933-2.488-10.668 0-20.478 3.009-34.331 3.93-14.378.956-29.426 1.997-42.978 2.268a37.74 37.74 0 01-6.14 1.7c-4.89.936-16.81 1.075-19.66 1.093-18.85 4.837-52.72 7.966-71.86 2.918-.8.003-5.54.015-7.92-.056-3.55-.107-7.01-.491-10.35-1.266-2.89.07-14.03.212-19.39-1.278-9.1-2.526-39.706.077-49.155 2.045-11.071 2.306-26.31.587-37.875.587-14.479 0-27.418-2.681-40.289-5.56-8.973-2.007-17.885-4.215-27.903-4.215-2.742 0-17.594 3.861-27.194 2.111-6.497-1.184-14.763 4.714-18.108 3.621-6.034-1.973-12.241 3.078-16.936 3.591-11.836 1.295-20.008-.645-22.361-1.625-3.947-1.645-8.154-4.445-9.796-5.587-2.567-.873-4.812-1.844-6.941-2.867-1.789-.859-3.554.421-6.724 0-3.804-.505-10.176.342-17.382 1.078-9.211.94-20.187-.353-38.455 3.256l-10.721-.107-9.22.406c-6.073-.785-20.452.035-21.25-1.087-3.138-4.405-18.169-3.053-18.169-3.053s-23.432 3.734-28.251 3.053c-9.406-1.328-19.666-.372-24.505.681-1.077.44-2.223.389-2.223.389-7.98 1.068-16.049 1.016-24.115.96-6.462-.045-13.017-.542-19.279 2.067-4.105 1.71-14.008 3.761-26.368 4.796-8.47.71-21.767-.284-29.117-.515-12.516-.395-22.483 2.102-28.074 1.737-.715.104-22.068 3.203-29.602 3.979-9.232.952-15.682-.897-18.044-1.881-7.375-3.072-26.296-3.604-36.199-3.604-2.503 0-30.054-1.85-30.054-1.85s-28.238 2.079-33.19 1.951c-4.524-.118-8.113-.946-10.847-1.951-5.17-1.057-11.132-2.845-14.486-4.994-1.324.519-1.92.325-4.032-.177-13.554 1.135-38.857 1.354-38.857 1.354s-18.257-3.968-30.963-6.13c-10.076-1.715-20.832.161-31.581 1.702-7.533 1.08-16.242 3.548-22.464 2.728-7.607-1.002-15.16.82-22.408.144-8.943.38-23.123 1.186-23.123 1.186s-13.482 1.407-17.99 1.363c-5.04 1.557-12 3.105-19.5 4.443-8.252 1.471-21.041 3.946-24.021 4.526-2.07 1.103-4.798 2.168-8.311 2.816-4.976.917-24.404-1.626-24.404-1.626L.003 66.731z'
			/>
		</svg>
	);
};

export default Divider;
