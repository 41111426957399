import React from 'react';
import styled from '@emotion/styled';
import Button from '../shared/Button';

import SectionDark from '../shared/SectionDark';
import TextBlock from '../shared/TextBlock';

import respond from '../../styles/mediaQueries';

const StyledHeader = styled('div')` 
	margin-bottom: 16rem;

	${respond('bp4')} {
		margin-bottom: 12rem;
	}
`;

const Grid = styled('div')` 
	display: grid;
	grid-template-columns: minmax(min-content, 44.8rem) 1fr;

	${respond('bp1')} {
		grid-template-columns: 1fr 1fr;
	}

	${respond('bp4')} {
		grid-template-columns: 1fr;
		margin: auto;
	}

`;

const Heading = styled('div')`
	grid-row: 1 / 2;
	grid-column: 1 / end;

	${respond('bp4')} {
		grid-column: 1 / 2;
	}
`;

const TextSection = styled('div')`
	${respond('bp4')} {
		margin-bottom: 2.4rem;
	}
`;

const FormSection = styled('div')`
	flex: 0 1 56rem;
	display: flex;
	justify-content: flex-end;

	${respond('bp4')} {
		justify-content: center;
		width: 100%;
	}
`;

const Form = styled('form')` 
	flex: 0 1 56rem;
	display: flex;
	flex-direction: column;
	margin-left: 4rem;

	${respond('bp4')} {
		grid-row: 3 / 4;
		margin-left: 0;
		margin-bottom: 6.4rem;
	}

	& input {
		margin-bottom: 1.6rem;
		height: 6.4rem;
		padding: 1.6rem;
		background: none;
		border: .3rem solid var(--textInverse2);
		font-family: var(--fontBody);
		color: var(--textInverse2);
		border-radius: .2rem;

		${respond('bp3')} {
			height: 5.6rem;
		}
	}

	& textarea {
		height: 24rem;
		padding: 1.6rem;
		margin-bottom: 5.6rem;
		background: none;
		border: .3rem solid var(--textInverse2);
		font-family: var(--fontBody);
		color: var(--textInverse2);
		border-radius: .2rem;

		${respond('bp4')} {
			height: 16rem;
			margin-bottom: 4rem;
		}

	}

	& label {
		font-family: var(--fontHeader);
	}
`;

const ContactLinks = styled('ul')`
	list-style-type: none;
	margin-top: 2.8rem;
`;

const ContactHeader = () => {
	return (
		<StyledHeader>
			<SectionDark>
				<Grid>
					<Heading>
						<h1>Contact</h1>
					</Heading>
					<TextSection>
						<TextBlock>
							<h5>brandon@bcdev.co</h5>
							<p>
								Brandon Corey<br />
								Kansas City, MO
							</p>

							<p>
								Let's build something together! I’m always
								interested to hear about new opportunities, no
								matter how big or small. Send me a message and
								I’ll get back to you via email ASAP.
							</p>

							<ContactLinks>
								<h5>Connect With Me</h5>
								<li>
									<a href='http://www.github.com/bcorey85'>
										Github
									</a>
								</li>
								<li>
									<a href='https://www.linkedin.com/in/brandon-corey-bb531a1b1/'>
										LinkedIn
									</a>
								</li>
								<li>
									<a href='https://twitter.com/bcdevkc'>
										Twitter
									</a>
								</li>
							</ContactLinks>
						</TextBlock>
					</TextSection>
					<FormSection>
						<Form
							name='contact'
							method='post'
							data-netlify='true'
							data-netlify-honeypot='bot-field'
							action='/contact-success'>
							<input
								type='hidden'
								name='form-name'
								value='contact'
							/>
							<label htmlFor='name'>Name</label>
							<input type='text' id='name' name='name' required />
							<label htmlFor='email'>Email</label>
							<input
								type='text'
								id='email'
								name='email'
								required
							/>
							<label htmlFor='comments'>Comments</label>
							<textarea id='comments' name='comments' required />
							<Button secondary>Send Me Your Thoughts</Button>
						</Form>
					</FormSection>
				</Grid>
			</SectionDark>
		</StyledHeader>
	);
};

export default ContactHeader;
