import React from 'react';
import styled from '@emotion/styled';
import Divider from '../svg/Divider';

import respond from '../../styles/mediaQueries';

const StyledSection = styled('section')`
	width: 100%;
	position: relative;
`;

const Content = styled('div')`
	height: ${props => props.height || 'auto'};
    background-color: var(--bgSecondary);
    color: var(--textInverse);
	padding: 8rem 6.4rem 4rem 6.4rem;
	border-top-left-radius: .2rem;
	border-top-right-radius: .2rem;

	${respond('bp1')} {
		padding: 6.4rem 5.2rem 4rem 5.2rem;
	}

	${respond('bp2')} {
		padding: 6.4rem 4rem 4rem 4rem;
	}

	${respond('bp3')} {
		padding: 4rem 3.2rem;
	}

	${respond('bp3')} {
		padding: 3.2rem 2.4rem;
	}

	${respond('bp4')} {
		padding: 4rem 1.2rem 4rem 1.2rem;
		margin-bottom: -.4rem;
	}

	${respond('bp5')} {
		margin-bottom: -.6rem
	}

	& p {
		color: var(--textInverse2);
	}

	& a {
		color: var(--textInverse);
		font-family: var(--fontHeader);
	}

	& h1,
	& h2, 
	& h3,
	& h4,
	& h5 {
		font-weight: 500;
	}
`;

const SectionDark = ({ children, height }) => {
	return (
		<StyledSection>
			<Content height={height}>{children}</Content>
			<Divider fill={'var(--bgSecondary)'} />
		</StyledSection>
	);
};

export default SectionDark;
