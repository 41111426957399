import React from 'react';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const StyledButton = styled('button')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6.4rem;
    padding: 0 2.4rem;
    text-align: center;
    cursor: pointer;
    transition: all .2s;
    font-family: var(--fontHeader);
    white-space: nowrap;
    font-size: inherit;
    border-radius: .2rem;
    font-weight: 500;

    ${respond('bp3')} {
        height: 5.6rem;
        line-height: 5.6rem;
    }

    ${respond('bp4')} {
        padding: 0 1.6rem;
        width: 100%;
    }
`;

const PrimaryButton = styled(StyledButton)`
    background-color: var(--bgSecondary);
    border: .3rem solid var(--bgSecondary);
    color: var(--textInverse);


    &:hover {
        background-color: var(--bgPrimary);
        border: .3rem solid var(--bgSecondary);
        color: var(--text);
    }
`;

const SecondaryButton = styled(StyledButton)`
    background-color: var(--white);
    border: .3rem solid var(--white);
    color: var(--black) !important;

    &:hover {
        background-color: var(--bgSecondary);
        border: .3rem solid var(--white);
        color: var(--textInverse) !important;
        text-decoration: none;
    }
`;

const Button = ({ secondary, children }) => {
	if (secondary) {
		return <SecondaryButton>{children}</SecondaryButton>;
	}

	return <PrimaryButton>{children}</PrimaryButton>;
};

export default Button;
