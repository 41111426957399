import React from 'react';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const StyledTextBlock = styled('div')` 
    max-width: 48rem;

    ${respond('bp1')} {
        max-width: 44rem;
    }

    & p:last-of-type {
        margin-bottom: 0;
    }
`;

const TextBlock = ({ className, children }) => {
	return <StyledTextBlock className={className}>{children}</StyledTextBlock>;
};

export default TextBlock;
